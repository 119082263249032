<template lang="pug">
#sell-admin
  b-row
    b-col(cols="12")
      h2 Listado de solicitudes
      p.text-secondary En esta sección prodras administrar todas las solicitudes de ventas de clientes, que quieren vender sus vehículos asi como tambien. <br> podras contactar y enviar email al cliente para gestionar su Vehículo y muchas otras cosas más.
      //- b-btn(type="button" @click="notifyMe") Show notification
    b-col(lg="6").my-1
      b-form-group
        b-form-input(
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Buscar vehículo"
        )
          b-input-group-append
            b-button(:disabled="!filter" @click="filter = ''") Clear
    b-col(sm="7" md="6" class="my-1")
      b-pagination(
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      )
  b-table(
    outlined
    sticky-header
    small
    :items="sell"
    :fields="fields"
    :filter="filter"
    :filter-included-fields="filterOn"
    :current-page="currentPage"
    :per-page="perPage"
    :tbody-tr-class="rowClass"
    primary-key="id"
    table-variant="dark"
  )
    template(v-slot:cell(fullname)="row")
      span {{ row.item.name }} {{ row.item.lastname }}
    template(v-slot:cell(vehicle)="row")
      span {{ row.item.brand.name }} {{ row.item.model.name }} {{ row.item.version }} {{ row.item.year }}
    template(v-slot:cell(contact)="row")
      a(:href="'mailto:'+row.item.email").d-block.mb-2
        font-awesome-icon(icon="envelope")
        span &nbsp;{{ row.item.email }}
    template(v-slot:cell(created_at)="row")
      span {{ $moment(row.item.created_at).format("DD/MM/YYYY") }}
    template(v-slot:cell(actions)="row")
      b-button(@click="showModalDetail(row.item)" variant="outline-primary" block size="sm") Detalle
      //b-badge(:variant="states[row.item.state].style") {{ states[row.item.state].name }}
  b-modal#modal-detail(hide-footer title="Detalle de solicitud" ref="modal-detail")
    div.d-block.text-center
      h3 Detalles de Solicitud
    b-table-simple
      caption Información del vehículo
      b-tr
        b-th
          i.icon-shield-check
          span &nbsp; MARCA
        b-td {{detail.brand}}
      b-tr
        b-th
          i.icon-car
          span &nbsp; MODELO
        b-td {{detail.model}}
      b-tr
        b-th
          font-awesome-icon(icon="car-side")
          span &nbsp; VERSIÓN
        b-td.text-uppercase {{detail.version}}
      b-tr
        b-th
          i.icon-calendar
          span &nbsp; AÑO
        b-td {{detail.year}}
      b-tr
        b-th
          i.icon-engine
          span.text-uppercase &nbsp; Cilindrada
        b-td {{detail.displacement}}
      b-tr
        b-th
          font-awesome-icon(icon="road")
          span.text-uppercase &nbsp; Kilometraje
        b-td {{ detail.mileage | formatNumber }} Kms.
      b-tr
        b-th
          i.icon-automatic(v-if="detail.transmission === 'Automático'")
          i.icon-mechanic(v-if="detail.transmission === 'Manual'")
          span.text-uppercase &nbsp; Transmisión
        b-td {{ detail.transmission }}
      b-tr
        b-th
          font-awesome-icon(icon="user")
          span.text-uppercase &nbsp; Dueños
        b-td {{ detail.owners }}
    hr
    b-table-simple
      caption Informacíon del dueño
      b-tr
        th
          font-awesome-icon(icon="user")
          span &nbsp; Nombre
        td {{ detail.name }}
      b-tr
        th
          font-awesome-icon(icon="envelope")
          span &nbsp; E-mail
        td
          a(:href="'mailto:'+detail.email").d-block.mb-2 {{ detail.email }}
      b-tr
        th
          font-awesome-icon(icon="phone-alt")
          span &nbsp;Teléfono:
        td
          a(:href="'tel:'+detail.phone").d-block {{ detail.phone }}
    b-button.mt-3(variant="outline-primary" block @click="hideModalDetail") Cerrar Detalle
</template>
<script>
export default {
  name:'SellAdmin',
  data(){
    return {
      sell:[],
      detail:null,
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'fullname', label: 'Nombre', sortable: true },
        { key: 'vehicle', label: 'Vehículo', sortable: true },
        { key: 'contact', label: 'Contacto', sortable: true },
        { key: 'created_at', label: 'Ingreso', sortable: true },
        { key: 'actions', label:'Acciones', sorteable: true },
      ],
      states:[
        { name:'Nueva Solicitud', style:'success'}
      ],
      filter: '',
      filterOn: [],
      perPage: 40,
      totalRows: 1,
      currentPage: 1,
    }
  },
  filters: {
    formatNumber: (value) => {
      if (!value) return ""
      value = value.toString()
      value = value.replaceAll(/\./g, "")
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted() {
    this.totalRows = this.sell.length
  },
  created(){
    this.getSell()
    this.cleanDetail()
  },
  methods: {
    notifyMe() {
      // Comprobamos si el navegador soporta las notificaciones
      if (!("Notification" in window)) {
        alert("Este navegador no soporta las notificaciones del sistema");
      }

      // Comprobamos si ya nos habían dado permiso
      else if (Notification.permission === "granted") {
        // Si esta correcto lanzamos la notificación
        var notification = new Notification("Holiwis :D");
      }

      // Si no, tendremos que pedir permiso al usuario
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
          // Si el usuario acepta, lanzamos la notificación
          if (permission === "granted") {
            var notification = new Notification("Gracias majo!");
          }
        });
      }

      // Finalmente, si el usuario te ha denegado el permiso y
      // quieres ser respetuoso no hay necesidad molestar más.
    },
    async getSell(){
      try{
        const { data: {data, success} } = await this.axios.get('/api/v1/sell')
        this.sell = data
      }
      catch (errors) {
        console.log(errors);
      }
    },
    showModalDetail(item) {
      this.setDetail(item)
      this.markRead(item)
      this.$refs['modal-detail'].show()
    },
    hideModalDetail() {
      this.$refs['modal-detail'].hide()
      this.cleanDetail()
    },
    cleanDetail(){
      this.detail = {
        brand:null,
        model:null,
        version:null,
        year:null,
        displacement:null,
        mileage:null,
        transmission:null,
        owners:null,
        name:null,
        email:null,
        phone:null
      }
    },
    setDetail(item){
      this.detail = {
        brand:item.brand.name,
        model:item.model.name,
        version:item.version,
        year:item.year,
        displacement:item.displacement,
        mileage:item.mileage,
        transmission:item.transmission,
        owners:item.owners,
        name:`${item.name} ${item.lastname}`,
        email:item.email,
        phone:item.phone
      }
    },
    async markRead(item){
      if(item.state === 0){
        item.state = true
        await this.sell.splice(item.id, item)
        const { data } = await this.$axios.put(`/api/v1/sell/${item.id}`, { state: true })
        console.log(data)
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.state === 0) return 'table-warning'
      if (item.state === 2) return 'table-primary'
    }
  }
}
</script>
<style lang="scss">
  #sell-admin{
    min-height: 70vh;
  }
  .table.b-table > caption {
    caption-side: top;
  }
  .table-light tbody+tbody, .table-light td, .table-light th, .table-light thead th {
    vertical-align: baseline;
  }
  .b-table-sticky-header {
    max-height: 100%;
  }
</style>