<template lang="pug">
.dashboard
  .dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
    Sidebar-user
  .dashboard-content
    b-container
      b-row
        b-col.pt-4
          sell-list
    FooterNavbarMobile
</template>
<script>
import SellList from '../components/sell/ListSell.vue'
import SidebarUser from "@/components/dashboard/SidebarUser";
import FooterNavbarMobile from "@/components/FooterNavbarMobile.vue"
export default {
  name: "SellAdmin",
  components: {
    SellList,
    SidebarUser,
    FooterNavbarMobile
  },
}
</script>