<template lang="pug">
#footerMobile.d-block.d-md-none
	b-nav(justified)
		li.nav-item
			router-link(to="/").nav-link
				i.icon-home
				span.d-block Inicio
		li.nav-item
			router-link(to="/filters?bodywork=").nav-link
				i.icon-car
				span.d-block Vehiculos
		//b-nav-item(href="/favorite")
			i.icon-heart
			span.d-block Favoritos
		li.nav-item
			router-link(to="/new-products").nav-link.text-mhmotors
				i.icon-plus-circle
				span.d-block Publicar
		li.nav-item
			router-link(to="/dashboard").nav-link
				i.icon-store
				span.d-block Mis Vehículos
		li.nav-item
			router-link(to="profile").nav-link
				b-avatar(v-if="!user.avatar" :text="getName" size="1.8rem")
				b-avatar(v-else, :src="getImage", size="1.8rem", :title="user.name")
				span.d-block Mi Perfil

</template>
<script>
	import { mapState } from "vuex";

	export default {
		name: "FooterNavbarMobile",
		data() {
			return {
				user: {},
				name: ""
			};
		},
		created() {
			this.user = this.credentials
		},
		computed:{
			...mapState('auth',['isAdmin','credentials']),
			...mapState(['bodyworks']),
			getImage(){
				if(this.user.avatar.length > 30)
					return this.user.avatar
				else
					return `${this.$axios.defaults.baseURL}/images/profile/${this.user.avatar}`
			},
			getName() {
				if(this.user.name){
					const userName = this.user.name.replace(/\./g, "").trim()
					let fullname = userName.split(" ")
					if(fullname[1])
						return fullname[0].charAt(0)+fullname[1].charAt(0)
					else
						return userName.substring(0,2)
				}else{
					return ""
				}
			},
		}
	}
</script>
<style lang="scss">
	@import "@/assets/scss/_variables";

	#footerMobile{
		display: block;
		overflow: hidden;
		text-align: center;
		.nav{
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			background-color: white;
			box-shadow: -5px 0 18px rgba(0,0,0,.1);
			a{
				color: #666;
				font-size: 1.95rem;
				padding: .5rem;
				i{
					vertical-align: middle;
				}
				span{
					font-size: .69rem;
					font-weight: 700;
					margin-top: -7px;
					white-space: nowrap;
					& > span {
						margin-top: 0 !important;
					}
				}
			}
			.navbar-nav{
				.nav-link{
					color: rgba(0,0,0,.5);
				}
			}
		}
		.bg-light{
			background-color: white !important;
		}
		.text-mhmotors{
			a{
				color: $primary !important
			}
		}
	}
</style>