<template lang="pug">
b-list-group#sidebar.text-center.bg-dark
  router-link(
    :to="{name:'profile'}"
    active-class="active"
  ).list-group-item.list-group-item-action
    i.icon-user-circle.fa-3x
    .d-block Perfil
  router-link(
    :to="{name:'dashboard'}"
    active-class="active"
  ).list-group-item.list-group-item-action
    i.icon-car.fa-3x
    .d-block Mis Vehículos
  //router-link(
      v-if="(isAdmin||isProfessional)"
      to="my-shop"
      active-class="active"
    ).list-group-item.list-group-item-action
      i.icon-store.fa-3x
      .d-block Mi Tienda
  router-link( v-if="isAdmin" :to="{name:'credits-all'}" active-class="active" ).list-group-item.list-group-item-action
    font-awesome-icon(:icon="['fas', 'coins']" size="2x")
    .d-block Creditos
  router-link( v-if="isAdmin" :to="{name:'sell-admin'}" active-class="active" ).list-group-item.list-group-item-action
    font-awesome-icon(:icon="['fas', 'tags']" size="2x")
    .d-block Solicitudes
  router-link(
    v-if="isAdmin"
    :to="{name:'users'}"
    active-class="active"
  ).list-group-item.list-group-item-action
    i.icon-users-crown.fa-3x
    .d-block Usuarios
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SidebarUser",
  data() {
    return {
      listItem: {},
    };
  },
  computed: {
    ...mapState("auth", ["isAdmin", "isProfessional"]),
  },
};
</script>
<style lang="scss">
@import ".././../assets/scss/_variables";
#sidebar {
  overflow-x: hidden;
  position: fixed;
  top: 80px;
  bottom: 0;
  background-color: #343a40;
  .list-group-item {
    background-color: #343a40;
    &-action {
      color: #a2a2a2;
      transition: all 0.3s;
    }
    &.active {
      color: white;
      background-color: $primary;
      border-color: $primary;
    }
  }
}
</style>
